// Basic error handling - should be adjusted for production

const API_URL = "/api";

export const login = async (email, password, persistent) => {
  try {
    let result = await fetch(`${API_URL}/user/login`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        persistent: persistent,
      }),
    });
    if (result.ok) {
      return await result.json();
    } else {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const getUser = async () => {
  try {
    let result = await fetch(`${API_URL}/user`);

    if (result.ok) {
      return await result.json();
    } else {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const logout = async (email) => {
  try {
    let result = await fetch(`${API_URL}/user/${email}/logout`, {
      method: "POST",
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const register = async (email, password, language, baseURL) => {
  try {
    let result = await fetch(`${API_URL}/user`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        language: language,
        base_url: baseURL,
      }),
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const reset = async (code, password) => {
  try {
    let result = await fetch(`${API_URL}/user/reset`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        code: code,
        password: password,
      }),
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const changePassword = async (password) => {
  try {
    let result = await fetch(`${API_URL}/user/password`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        password: password,
      }),
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const requestReset = async (email, baseURL) => {
  try {
    let result = await fetch(`${API_URL}/user/${email}/reset`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        base_url: baseURL,
      }),
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const getAssessment = async (id) => {
  try {
    let result = await fetch(`${API_URL}/assessments/${id}`);
    if (result.ok) {
      return await result.json();
    } else {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const deleteAssessment = async (id) => {
  try {
    let result = await fetch(`${API_URL}/assessments/${id}`, {
      method: "DELETE",
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const createAssessment = async (title, period, copy, template, criteria) => {
  try {
    let request = {
      title: title,
      period: period,
    };

    if (!copy) {
      request.criteria = criteria;
    }

    if (template) {
      request.template = template;
    } else if (copy) {
      request.copy = copy;
    }

    let result = await fetch(`${API_URL}/assessments`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(request),
    });

    if (result.ok) {
      return await result.text();
    } else {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const addObjective = async (id, objective) => {
  try {
    let result = await fetch(`${API_URL}/assessments/${id}/objective`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        objective: objective,
      }),
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const updateObjective = async (id, objective, description) => {
  try {
    let result = await fetch(`${API_URL}/assessments/${id}/objective`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        objective: objective,
        description: description,
      }),
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const deleteObjective = async (id, objective) => {
  try {
    let result = await fetch(`${API_URL}/assessments/${id}/objective`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        objective: objective,
      }),
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const addRisk = async (id, objective, risk) => {
  try {
    let result = await fetch(`${API_URL}/assessments/${id}/risk`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        objective: objective,
        risk: risk,
      }),
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const addRisksAI = async (id, objective) => {
  try {
    let result = await fetch(`${API_URL}/assessments/${id}/risk/ai`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        objective: objective,
      }),
    });

    if (result.ok) {
      return await result.json();
    } else {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const updateRisk = async (id, objective, risk, description, inherentProbability, inherentImpact, residualProbability, residualImpact) => {
  try {
    let result = await fetch(`${API_URL}/assessments/${id}/risk`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        objective: objective,
        risk: risk,
        description: description,
        inherent_probability: inherentProbability,
        inherent_impact: inherentImpact,
        residual_probability: residualProbability,
        residual_impact: residualImpact,
      }),
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const deleteRisk = async (id, objective, risk) => {
  try {
    let result = await fetch(`${API_URL}/assessments/${id}/risk`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        objective: objective,
        risk: risk,
      }),
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const addControl = async (id, objective, risk, control) => {
  try {
    let result = await fetch(`${API_URL}/assessments/${id}/control`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        objective: objective,
        risk: risk,
        control: control,
      }),
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const updateControl = async (id, objective, risk, control, description) => {
  try {
    let result = await fetch(`${API_URL}/assessments/${id}/control`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        objective: objective,
        risk: risk,
        control: control,
        description: description,
      }),
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const deleteControl = async (id, objective, risk, control) => {
  try {
    let result = await fetch(`${API_URL}/assessments/${id}/control`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        objective: objective,
        risk: risk,
        control: control,
      }),
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const addAction = async (id, action) => {
  try {
    let result = await fetch(`${API_URL}/assessments/${id}/action`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(action),
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const updateAction = async (id, action) => {
  try {
    let result = await fetch(`${API_URL}/assessments/${id}/action`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(action),
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const deleteAction = async (id, action) => {
  try {
    let result = await fetch(`${API_URL}/assessments/${id}/action`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(action),
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const getAcl = async (id) => {
  try {
    let result = await fetch(`${API_URL}/assessments/${id}/acl`);
    if (result.ok) {
      return await result.json();
    } else {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const updateACl = async (id, acl) => {
  try {
    let result = await fetch(`${API_URL}/assessments/${id}/acl`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(acl),
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const checkUserExists = async (email) => {
  try {
    let result = await fetch(`${API_URL}/user/${email}/exists`);
    if (result.ok) {
      return await result.json();
    } else {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const createCriteria = async (criteria) => {
  try {
    let result = await fetch(`${API_URL}/criteria`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(criteria),
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const updateCriteria = async (criteria) => {
  try {
    let result = await fetch(`${API_URL}/criteria`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(criteria),
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const deleteCriteria = async (name) => {
  try {
    let result = await fetch(`${API_URL}/criteria/${name}`, {
      method: "DELETE",
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const getReport = async (id, reportFlags) => {
  let url = `${API_URL}/report/${id}?${reportFlags.summary ? "summary&" : ""}${reportFlags.plan ? "plan&" : ""}${reportFlags.risk_annex ? "risk_annex&" : ""}${
    reportFlags.control_annex ? "control_annex&" : ""
  }${reportFlags.criteria_annex ? "criteria_annex" : ""}`;

  try {
    let result = await fetch(url);
    if (result.ok) {
      return await result.blob();
    } else {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const changeLanguage = async (language) => {
  try {
    let result = await fetch(`${API_URL}/user/language`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        language: language,
      }),
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const purchaseLicense = async (tier) => {
  try {
    let result = await fetch(`${API_URL}/license`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        tier: tier,
      }),
    });

    if (result.ok) {
      return await result.text();
    } else {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};

export const contact = async (message) => {
  try {
    let result = await fetch(`${API_URL}/contact/app`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        message: message,
      }),
    });

    if (!result.ok) {
      let apiError = new Error(await result.text());
      apiError.name = "APIError";
      apiError.status = result.status;
      throw apiError;
    }
  } catch (error) {
    if (error.name !== "APIError") {
      error.name = "NetworkingError";
    }
    throw error;
  }
};
