import React, { useState } from "react";

import Warning from "./Warning";

import { purchaseLicense } from "../services/RiskScribeSDK";
import { t } from "../services/i18n";

const License = ({ language, tier, expiration }) => {
  const dateFormat = (value) => {
    let date = new Date(value);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    return language === "es" ? `${day}/${month}/${year}` : `${month}/${day}/${year}`;
  };

  const planName = (value) => {
    switch (value) {
      case 3:
        return t("Corporate");
      case 2:
        return t("Professional");
      case 1:
        return t("Personal");
      default:
        return t("Free");
    }
  };

  const handlePurchase = async () => {
    let stripeURL = await purchaseLicense(purchaseTier);
    setShowPurchaseMessage(false);
    window.location.href = stripeURL;
  };

  const [showPurchaseMessage, setShowPurchaseMessage] = useState(false);
  const [purchaseTier, setPurchaseTier] = useState(0);

  return (
    <>
      <div className="container mt-4">
        <div className="columns is-centered">
          <div className="column is-half">
            <div className="box">
              <h1 className="is-size-3 font-rubik">{t("Plan")}</h1>
              <p>
                {t("Current plan: ")} <b>{planName(tier)}.</b>
              </p>
              {tier !== 0 && expiration !== 0 && (
                <p>
                  {t("Your license is valid until: ")} <b>{dateFormat(expiration)}</b>
                </p>
              )}
              {tier !== 0 && expiration === 0 && <p>{t("You have a perpetual license")}.</p>}
              <div className="mt-6 columns is-mobile is-centered">
                <div className="column is-half">
                  {tier === 0 && (
                    <div>
                      <button
                        className="button is-primary mb-4 is-fullwidth"
                        onClick={() => {
                          setPurchaseTier(1);
                          setShowPurchaseMessage(true);
                        }}
                      >
                        {t("Upgrade to the Personal plan")}
                      </button>
                    </div>
                  )}
                  {tier === 1 && expiration !== 0 && (
                    <div>
                      <button
                        className="button is-primary mb-4 is-fullwidth"
                        onClick={() => {
                          setPurchaseTier(1);
                          setShowPurchaseMessage(true);
                        }}
                      >
                        {t("Extend your Personal plan")}
                      </button>
                    </div>
                  )}
                  {tier <= 1 && (
                    <div>
                      <button
                        className="button is-primary mb-4 is-fullwidth"
                        onClick={() => {
                          setPurchaseTier(2);
                          setShowPurchaseMessage(true);
                        }}
                      >
                        {t("Upgrade to the Professional plan")}
                      </button>
                    </div>
                  )}
                  {tier === 2 && expiration !== 0 && (
                    <div>
                      <button
                        className="button is-primary mb-4 is-fullwidth"
                        onClick={() => {
                          setPurchaseTier(2);
                          setShowPurchaseMessage(true);
                        }}
                      >
                        {t("Extend your Professional plan")}
                      </button>
                    </div>
                  )}
                  {tier <= 2 && (
                    <div>
                      <button
                        className="button is-primary mb-4 is-fullwidth"
                        onClick={() => {
                          setPurchaseTier(3);
                          setShowPurchaseMessage(true);
                        }}
                      >
                        {t("Upgrade to the Corporate plan")}
                      </button>
                    </div>
                  )}
                  {tier === 3 && expiration !== 0 && (
                    <div>
                      <button
                        className="button is-primary mb-4 is-fullwidth"
                        onClick={() => {
                          setPurchaseTier(3);
                          setShowPurchaseMessage(true);
                        }}
                      >
                        {t("Extend your Corporate plan")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="has-text-centered is-size-6 is-underlined">
                <a href={language === "es" ? "https://stage.riskscribe.com/es/plans/" : "https://stage.riskscribe.com/en/plans/"} target="_blank">
                  {t("Compare plans")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Warning
        show={showPurchaseMessage}
        onConfirm={handlePurchase}
        onCancel={() => setShowPurchaseMessage(false)}
        title={t("Notice")}
        content={t(
          "You will now be redirected to Stripe (one of the leading and most secure global payment services) to pay for your license. The RiskScribe platform does not access or store your payment information."
        )}
      />
    </>
  );
};

export default License;
